export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#ffffff"},{"name":"format-detection","content":"telephone=no"},{"hid":"og:url","property":"og:url","content":"/"},{"hid":"og:site_name","property":"og:site_name","content":"suitmedia"},{"hid":"fb:app_id","property":"fb:app_id","content":""},{"hid":"twitter:site","name":"twitter:site","content":"/"},{"hid":"keywords","name":"keywords","content":""}],"link":[{"rel":"apple-touch-icon","href":"/apple-icon.png"},{"rel":"icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[],"noscript":[],"title":"Suitmedia","charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appKeepalive = {"max":4}

export const appBuildAssetsDir = "assets"

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'