import revive_payload_client_4sVQNw7RlN from "/var/www/html/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/html/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/html/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import siteConfig_Eeyr9ytLpT from "/var/www/html/node_modules/nuxt-site-config/dist/runtime/plugins/siteConfig.mjs";
import payload_client_yVLowv6hDl from "/var/www/html/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/html/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/html/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/var/www/html/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_tbFNToZNim from "/var/www/html/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/var/www/html/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import chunk_reload_client_UciE0i6zes from "/var/www/html/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/html/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import vue_select_Hligc0DBp2 from "/var/www/html/plugins/vue-select.js";
import recaptcha_W4US4xmBV6 from "/var/www/html/plugins/recaptcha.js";
import vue_social_sharing_DwrRDn6E2J from "/var/www/html/plugins/vue-social-sharing.js";
import plugin_client_LRRE9o5vvF from "/var/www/html/.nuxt/nuxt-speedkit/plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  siteConfig_Eeyr9ytLpT,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  plugin_tbFNToZNim,
  plugin_iGLjBbor9q,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  vue_select_Hligc0DBp2,
  recaptcha_W4US4xmBV6,
  vue_social_sharing_DwrRDn6E2J,
  plugin_client_LRRE9o5vvF
]