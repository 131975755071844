import { default as _91static_93AwXM1Fs4MtMeta } from "/var/www/html/pages/[static].vue?macro=true";
import { default as approach4kIIzoJBmXMeta } from "/var/www/html/pages/about/approach.vue?macro=true";
import { default as historycwko8AmmLxMeta } from "/var/www/html/pages/about/history.vue?macro=true";
import { default as indextu5vNqDNuuMeta } from "/var/www/html/pages/about/index.vue?macro=true";
import { default as purposef4xFvtd32UMeta } from "/var/www/html/pages/about/purpose.vue?macro=true";
import { default as teamDBhBjgcfEBMeta } from "/var/www/html/pages/about/team.vue?macro=true";
import { default as _91slug_93dPJ3nxiEtCMeta } from "/var/www/html/pages/careers/[slug].vue?macro=true";
import { default as cultureUJVkBxe5WbMeta } from "/var/www/html/pages/careers/culture.vue?macro=true";
import { default as indexwoFQ5uJnz5Meta } from "/var/www/html/pages/careers/index.vue?macro=true";
import { default as contactZjsR3yTbTmMeta } from "/var/www/html/pages/contact.vue?macro=true";
import { default as _91slug_93FbgXc6T368Meta } from "/var/www/html/pages/expertises/[slug].vue?macro=true";
import { default as indexiDR63hclhOMeta } from "/var/www/html/pages/expertises/index.vue?macro=true";
import { default as _91slug_93ytevEb072xMeta } from "/var/www/html/pages/ideas/[slug].vue?macro=true";
import { default as index49gNhfUTT5Meta } from "/var/www/html/pages/ideas/index.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as _91slug_93By27GN49FIMeta } from "/var/www/html/pages/industries/[slug].vue?macro=true";
import { default as indexsflqHqNdrgMeta } from "/var/www/html/pages/industries/index.vue?macro=true";
import { default as indexDMk6BCwKOeMeta } from "/var/www/html/pages/insights/index.vue?macro=true";
import { default as maintenanceFfl67w51TlMeta } from "/var/www/html/pages/maintenance.vue?macro=true";
import { default as successiWWc1sCNv0Meta } from "/var/www/html/pages/newsletter/success.vue?macro=true";
import { default as indexTi2v2AFSZRMeta } from "/var/www/html/pages/podcasts/index.vue?macro=true";
import { default as _91slug_93UanbeaRGiNMeta } from "/var/www/html/pages/products/[slug].vue?macro=true";
import { default as indexikhioupTWxMeta } from "/var/www/html/pages/products/index.vue?macro=true";
import { default as searchhcB92YYWLxMeta } from "/var/www/html/pages/search.vue?macro=true";
import { default as _91slug_93YqYc2GbMWsMeta } from "/var/www/html/pages/solutions/[slug].vue?macro=true";
import { default as index674m5y27XBMeta } from "/var/www/html/pages/solutions/index.vue?macro=true";
import { default as _91slug_93Myr740DVYXMeta } from "/var/www/html/pages/sustainability/[slug].vue?macro=true";
import { default as indexK4leLMw8LyMeta } from "/var/www/html/pages/sustainability/index.vue?macro=true";
import { default as _91slug_93ZWlUqb78RhMeta } from "/var/www/html/pages/videos/[slug].vue?macro=true";
import { default as indextz3zSuR1dAMeta } from "/var/www/html/pages/videos/index.vue?macro=true";
import { default as _91slug_93dEhsaIasxaMeta } from "/var/www/html/pages/work/[slug].vue?macro=true";
import { default as indexfIQz7HXQ0EMeta } from "/var/www/html/pages/work/index.vue?macro=true";
export default [
  {
    name: _91static_93AwXM1Fs4MtMeta?.name ?? "static",
    path: _91static_93AwXM1Fs4MtMeta?.path ?? "/:static()",
    meta: _91static_93AwXM1Fs4MtMeta || {},
    alias: _91static_93AwXM1Fs4MtMeta?.alias || [],
    redirect: _91static_93AwXM1Fs4MtMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/[static].vue").then(m => m.default || m)
  },
  {
    name: approach4kIIzoJBmXMeta?.name ?? "about-approach",
    path: approach4kIIzoJBmXMeta?.path ?? "/about/approach",
    meta: approach4kIIzoJBmXMeta || {},
    alias: approach4kIIzoJBmXMeta?.alias || [],
    redirect: approach4kIIzoJBmXMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/about/approach.vue").then(m => m.default || m)
  },
  {
    name: historycwko8AmmLxMeta?.name ?? "about-history",
    path: historycwko8AmmLxMeta?.path ?? "/about/history",
    meta: historycwko8AmmLxMeta || {},
    alias: historycwko8AmmLxMeta?.alias || [],
    redirect: historycwko8AmmLxMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/about/history.vue").then(m => m.default || m)
  },
  {
    name: indextu5vNqDNuuMeta?.name ?? "about",
    path: indextu5vNqDNuuMeta?.path ?? "/about",
    meta: indextu5vNqDNuuMeta || {},
    alias: indextu5vNqDNuuMeta?.alias || [],
    redirect: indextu5vNqDNuuMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: purposef4xFvtd32UMeta?.name ?? "about-purpose",
    path: purposef4xFvtd32UMeta?.path ?? "/about/purpose",
    meta: purposef4xFvtd32UMeta || {},
    alias: purposef4xFvtd32UMeta?.alias || [],
    redirect: purposef4xFvtd32UMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/about/purpose.vue").then(m => m.default || m)
  },
  {
    name: teamDBhBjgcfEBMeta?.name ?? "about-team",
    path: teamDBhBjgcfEBMeta?.path ?? "/about/team",
    meta: teamDBhBjgcfEBMeta || {},
    alias: teamDBhBjgcfEBMeta?.alias || [],
    redirect: teamDBhBjgcfEBMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dPJ3nxiEtCMeta?.name ?? "careers-slug",
    path: _91slug_93dPJ3nxiEtCMeta?.path ?? "/careers/:slug()",
    meta: _91slug_93dPJ3nxiEtCMeta || {},
    alias: _91slug_93dPJ3nxiEtCMeta?.alias || [],
    redirect: _91slug_93dPJ3nxiEtCMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/careers/[slug].vue").then(m => m.default || m)
  },
  {
    name: cultureUJVkBxe5WbMeta?.name ?? "careers-culture",
    path: cultureUJVkBxe5WbMeta?.path ?? "/careers/culture",
    meta: cultureUJVkBxe5WbMeta || {},
    alias: cultureUJVkBxe5WbMeta?.alias || [],
    redirect: cultureUJVkBxe5WbMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/careers/culture.vue").then(m => m.default || m)
  },
  {
    name: indexwoFQ5uJnz5Meta?.name ?? "careers",
    path: indexwoFQ5uJnz5Meta?.path ?? "/careers",
    meta: indexwoFQ5uJnz5Meta || {},
    alias: indexwoFQ5uJnz5Meta?.alias || [],
    redirect: indexwoFQ5uJnz5Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: contactZjsR3yTbTmMeta?.name ?? "contact",
    path: contactZjsR3yTbTmMeta?.path ?? "/contact",
    meta: contactZjsR3yTbTmMeta || {},
    alias: contactZjsR3yTbTmMeta?.alias || [],
    redirect: contactZjsR3yTbTmMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FbgXc6T368Meta?.name ?? "expertises-slug",
    path: _91slug_93FbgXc6T368Meta?.path ?? "/expertises/:slug()",
    meta: _91slug_93FbgXc6T368Meta || {},
    alias: _91slug_93FbgXc6T368Meta?.alias || [],
    redirect: _91slug_93FbgXc6T368Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/expertises/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexiDR63hclhOMeta?.name ?? "expertises",
    path: indexiDR63hclhOMeta?.path ?? "/expertises",
    meta: indexiDR63hclhOMeta || {},
    alias: indexiDR63hclhOMeta?.alias || [],
    redirect: indexiDR63hclhOMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/expertises/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ytevEb072xMeta?.name ?? "ideas-slug",
    path: _91slug_93ytevEb072xMeta?.path ?? "/ideas/:slug()",
    meta: _91slug_93ytevEb072xMeta || {},
    alias: _91slug_93ytevEb072xMeta?.alias || [],
    redirect: _91slug_93ytevEb072xMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/ideas/[slug].vue").then(m => m.default || m)
  },
  {
    name: index49gNhfUTT5Meta?.name ?? "ideas",
    path: index49gNhfUTT5Meta?.path ?? "/ideas",
    meta: index49gNhfUTT5Meta || {},
    alias: index49gNhfUTT5Meta?.alias || [],
    redirect: index49gNhfUTT5Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/ideas/index.vue").then(m => m.default || m)
  },
  {
    name: indexUbNeHw0dYYMeta?.name ?? "index",
    path: indexUbNeHw0dYYMeta?.path ?? "/",
    meta: indexUbNeHw0dYYMeta || {},
    alias: indexUbNeHw0dYYMeta?.alias || [],
    redirect: indexUbNeHw0dYYMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93By27GN49FIMeta?.name ?? "industries-slug",
    path: _91slug_93By27GN49FIMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93By27GN49FIMeta || {},
    alias: _91slug_93By27GN49FIMeta?.alias || [],
    redirect: _91slug_93By27GN49FIMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexsflqHqNdrgMeta?.name ?? "industries",
    path: indexsflqHqNdrgMeta?.path ?? "/industries",
    meta: indexsflqHqNdrgMeta || {},
    alias: indexsflqHqNdrgMeta?.alias || [],
    redirect: indexsflqHqNdrgMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexDMk6BCwKOeMeta?.name ?? "insights",
    path: indexDMk6BCwKOeMeta?.path ?? "/insights",
    meta: indexDMk6BCwKOeMeta || {},
    alias: indexDMk6BCwKOeMeta?.alias || [],
    redirect: indexDMk6BCwKOeMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: maintenanceFfl67w51TlMeta?.name ?? "maintenance",
    path: maintenanceFfl67w51TlMeta?.path ?? "/maintenance",
    meta: maintenanceFfl67w51TlMeta || {},
    alias: maintenanceFfl67w51TlMeta?.alias || [],
    redirect: maintenanceFfl67w51TlMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: successiWWc1sCNv0Meta?.name ?? "newsletter-success",
    path: successiWWc1sCNv0Meta?.path ?? "/newsletter/success",
    meta: successiWWc1sCNv0Meta || {},
    alias: successiWWc1sCNv0Meta?.alias || [],
    redirect: successiWWc1sCNv0Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/newsletter/success.vue").then(m => m.default || m)
  },
  {
    name: indexTi2v2AFSZRMeta?.name ?? "podcasts",
    path: indexTi2v2AFSZRMeta?.path ?? "/podcasts",
    meta: indexTi2v2AFSZRMeta || {},
    alias: indexTi2v2AFSZRMeta?.alias || [],
    redirect: indexTi2v2AFSZRMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UanbeaRGiNMeta?.name ?? "products-slug",
    path: _91slug_93UanbeaRGiNMeta?.path ?? "/products/:slug()",
    meta: _91slug_93UanbeaRGiNMeta || {},
    alias: _91slug_93UanbeaRGiNMeta?.alias || [],
    redirect: _91slug_93UanbeaRGiNMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexikhioupTWxMeta?.name ?? "products",
    path: indexikhioupTWxMeta?.path ?? "/products",
    meta: indexikhioupTWxMeta || {},
    alias: indexikhioupTWxMeta?.alias || [],
    redirect: indexikhioupTWxMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: searchhcB92YYWLxMeta?.name ?? "search",
    path: searchhcB92YYWLxMeta?.path ?? "/search",
    meta: searchhcB92YYWLxMeta || {},
    alias: searchhcB92YYWLxMeta?.alias || [],
    redirect: searchhcB92YYWLxMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YqYc2GbMWsMeta?.name ?? "solutions-slug",
    path: _91slug_93YqYc2GbMWsMeta?.path ?? "/solutions/:slug()",
    meta: _91slug_93YqYc2GbMWsMeta || {},
    alias: _91slug_93YqYc2GbMWsMeta?.alias || [],
    redirect: _91slug_93YqYc2GbMWsMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: index674m5y27XBMeta?.name ?? "solutions",
    path: index674m5y27XBMeta?.path ?? "/solutions",
    meta: index674m5y27XBMeta || {},
    alias: index674m5y27XBMeta?.alias || [],
    redirect: index674m5y27XBMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Myr740DVYXMeta?.name ?? "sustainability-slug",
    path: _91slug_93Myr740DVYXMeta?.path ?? "/sustainability/:slug()",
    meta: _91slug_93Myr740DVYXMeta || {},
    alias: _91slug_93Myr740DVYXMeta?.alias || [],
    redirect: _91slug_93Myr740DVYXMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/sustainability/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexK4leLMw8LyMeta?.name ?? "sustainability",
    path: indexK4leLMw8LyMeta?.path ?? "/sustainability",
    meta: indexK4leLMw8LyMeta || {},
    alias: indexK4leLMw8LyMeta?.alias || [],
    redirect: indexK4leLMw8LyMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZWlUqb78RhMeta?.name ?? "videos-slug",
    path: _91slug_93ZWlUqb78RhMeta?.path ?? "/videos/:slug()",
    meta: _91slug_93ZWlUqb78RhMeta || {},
    alias: _91slug_93ZWlUqb78RhMeta?.alias || [],
    redirect: _91slug_93ZWlUqb78RhMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/videos/[slug].vue").then(m => m.default || m)
  },
  {
    name: indextz3zSuR1dAMeta?.name ?? "videos",
    path: indextz3zSuR1dAMeta?.path ?? "/videos",
    meta: indextz3zSuR1dAMeta || {},
    alias: indextz3zSuR1dAMeta?.alias || [],
    redirect: indextz3zSuR1dAMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/videos/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dEhsaIasxaMeta?.name ?? "work-slug",
    path: _91slug_93dEhsaIasxaMeta?.path ?? "/work/:slug()",
    meta: _91slug_93dEhsaIasxaMeta || {},
    alias: _91slug_93dEhsaIasxaMeta?.alias || [],
    redirect: _91slug_93dEhsaIasxaMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/work/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexfIQz7HXQ0EMeta?.name ?? "work",
    path: indexfIQz7HXQ0EMeta?.path ?? "/work",
    meta: indexfIQz7HXQ0EMeta || {},
    alias: indexfIQz7HXQ0EMeta?.alias || [],
    redirect: indexfIQz7HXQ0EMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/work/index.vue").then(m => m.default || m)
  }
]